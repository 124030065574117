import { omit } from "lodash";
import { RecPurchaseFormData, RetireFormData } from "../types/appTypes";
import applicationApi from "./applicationApi";

type createOutgoingRequestParams =
  | {
      action: "retire";
      address: string;
      quantity: number;
      year: number;
      behalf: string;
    }
  | {
      action: "bridge";
      address: string;
      quantity: number;
      organizationId: string;
    };

export const createOutgoingRequest = (params: createOutgoingRequestParams) => {
  return applicationApi.post(`/${params.action}`, omit(params, "action"));
};

export const makeCheckoutFormRequest = async (
  retireDetails: RetireFormData | RecPurchaseFormData,
  checkoutType: "retirement" | "purchase"
) => {
  const {
    data: { checkoutUrl },
  } = await applicationApi.post<{ checkoutUrl: string }>("/checkout", {
    ...retireDetails,
    checkoutType,
  });
  window.location.replace(checkoutUrl);
};
