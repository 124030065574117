import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Stack } from "@mantine/core";
import React, { createContext, useEffect, useState } from "react";
import CenteredLoader from "./components/designSystem/CenteredLoader";
import Footer from "./components/designSystem/Footer";
import Nav from "./components/navbar";
import AppContentWrapper from "./views/AppContentWrapper";

export enum Role {
  user,
  admin,
}

export const ViewContext = createContext<{
  setView?: (view: string) => void;
  view?: string;
  role?: Role;
  wallet?: string;
  setWallet?: (view: string) => void;
  balance?: string;
  setBalance?: (view: string) => void;
  isCorrectChainId?: boolean;
  setIsCorrectChainId?: (isCorrect: boolean) => void;
}>({});

function App() {
  const { isLoading, isAuthenticated, getIdTokenClaims } = useAuth0();
  const [view, setView] = useState("Retire");
  const [wallet, setWallet] = useState("");
  const [balance, setBalance] = useState("");
  const [role, setRole] = useState<Role>(Role.admin);
  const [isCorrectChainId, setIsCorrectChainId] = useState(false);
  useEffect(() => {
    const getClaims = async () => {
      const claims = await getIdTokenClaims();
      const roles = (claims?.userRoles as Array<String>) ?? [];
      return roles.includes("Admin");
    };

    if (isAuthenticated)
      getClaims().then((isAdmin) => setRole(isAdmin ? Role.admin : Role.user));
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <Stack h={"100vh"} sx={{ gap: 0 }}>
      {isLoading ? (
        <CenteredLoader size={"xl"} />
      ) : (
        <>
          <Flex className="">
            <ViewContext.Provider
              value={{
                view,
                setView,
                role,
                setBalance,
                balance,
                wallet,
                setWallet,
                isCorrectChainId,
                setIsCorrectChainId,
              }}
            >
              <Nav />
              <AppContentWrapper />
            </ViewContext.Provider>
          </Flex>
          <Footer />
        </>
      )}
    </Stack>
  );
}

export default App;
