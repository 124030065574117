import { Stack } from "@mantine/core";
import React from "react";

const ContentPage = ({ children }: { children: React.ReactNode }) => (
  <Stack p="xl" w="100%">
    {children}
  </Stack>
);

export default ContentPage;
