import {
  Anchor,
  Box,
  Button,
  List,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React from "react";
import ContentPage from "../../components/designSystem/ContentPage";
import OrderedListItem from "../../components/designSystem/Lists/OrderedListItem";
import applicationApi from "../../data/applicationApi";

type IncomingBridgeFormData = {
  serialNumber: string;
  serialNumberStart: string;
  serialNumberEnd: string;
  polygonAddress: string;
};

const validatePolygon = (address: string) =>
  /^0x[a-fA-F0-9]{40}$/.test(address);

/**
 * Creates a bridge request from MRETS REC to token
 */
const IncomingBridgeView = () => {
  const form = useForm<IncomingBridgeFormData>({
    initialValues: {
      serialNumber: "",
      serialNumberEnd: "",
      serialNumberStart: "",
      polygonAddress: "",
    },
    validate: {
      polygonAddress: (address) =>
        validatePolygon(address)
          ? null
          : "Please enter a valid polygon address",
    },
  });

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    //TODO check that serial numbers are good and that polygon address is real
    try {
      await applicationApi.post("bridge-request", {
        ...form.values,
        serialNumberStart: parseInt(form.values.serialNumberStart),
        serialNumberEnd: parseInt(form.values.serialNumberEnd),
      });
      alert(
        "Submission Received, your transfer is under review! We will never accept your REC transfer without minting the tokens to your address."
      );

      form.reset();
    } catch (err) {
      alert(
        "There was an error with your submission, please make sure all fields are correct"
      );
      console.log(err);
    }
  };

  return (
    <ContentPage>
      <Title>Bridge</Title>
      <Text>
        Follow the steps below to turn your MRETs RECs into GET. We only accept
        RECs that are{" "}
        <a
          href="https://www.green-e.org/sfdc/reports-data.php"
          target="_blank"
          rel="noreferrer"
        >
          CRS listed
        </a>
        .
      </Text>
      <List type="ordered" withPadding>
        <OrderedListItem>
          On the MRETs registry create a transaction sending the requested
          amount of RECs to the 1E main organization.
        </OrderedListItem>
        <OrderedListItem>
          Below, enter the serial number, serial number start, and serial number
          end of the certificates you wish to send. Then, enter your public
          polygon wallet address in the last field.
        </OrderedListItem>
        <OrderedListItem>
          After confirmation from our team, your tokens will arrive to your
          address.
        </OrderedListItem>
        <OrderedListItem>
          Questions?{" "}
          <Anchor<"a"> href={"mailto:support@rectrade.io"}>
            Email support@rectrade.io
          </Anchor>
        </OrderedListItem>
      </List>
      <Box sx={{ maxWidth: 400 }}>
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Serial Number"
            name="serialNumber"
            id="serialNumber"
            type="text"
            required
            withAsterisk
            {...form.getInputProps("serialNumber")}
          />
          <TextInput
            label="Serial Number Start"
            required
            withAsterisk
            type="number"
            name="serialNumberStart"
            id="serialNumberStart"
            step={1}
            min={0}
            {...form.getInputProps("serialNumberStart")}
          />
          <TextInput
            label="Serial Number End"
            required
            withAsterisk
            type="number"
            name="serialNumberEnd"
            id="serialNumberEnd"
            step={1}
            min={
              form.values.serialNumberStart
                ? parseInt(form.values.serialNumberStart) + 1
                : 1
            }
            {...form.getInputProps("serialNumberEnd")}
          />
          <TextInput
            label="Polygon Address"
            type="text"
            name="polygonAddress"
            id="polygonAddress"
            withAsterisk
            required
            {...form.getInputProps("polygonAddress")}
          />
          <Button type="submit" mt={"md"}>
            Submit
          </Button>
        </form>
      </Box>
    </ContentPage>
  );
};

export default IncomingBridgeView;
