import { groupBy } from "lodash";
import { useQuery } from "react-query";
import { CertificateQuantity } from "../../types/appTypes";
import applicationApi from "../applicationApi";

const groupCertificates = (data: CertificateQuantity[]) => {
  const groupedCerts = groupBy(data, "mrets_id");
  const certs = Object.keys(groupedCerts).map((mretsId) => {
    const certsWMretsId = groupedCerts[mretsId];
    const sum = certsWMretsId.reduce(
      (prev, current) => prev + current.quantity,
      0
    );

    return { ...certsWMretsId[0], sum };
  });
  return certs;
};

const fetcher = () =>
  applicationApi
    .get<CertificateQuantity[]>("/certificate_quantities")
    .then((response) => groupCertificates(response.data));

export function useInventoryQuery() {
  return useQuery("inventory", fetcher, { refetchOnWindowFocus: false });
}
