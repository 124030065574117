import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { createOutgoingRequest } from "../data/data";
import contractABI from "../web3/contractABI.json";

const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_KEY!);

export const tokenContract = new web3.eth.Contract(
  contractABI as any,
  process.env.REACT_APP_CONTRACT_ADDRESS
);

export const retireTokens = async (
  address: string,
  quantity: number,
  year: number,
  behalf: string,
  setLoading: (loading: boolean) => void
) => {
  //input error handling
  if (!window.ethereum || address === null) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }

  if (!quantity || quantity <= 0) {
    return {
      status: "❌ Your quantity must be greater than 0",
    };
  }

  setLoading(true);
  //set up transaction parameters

  //sign the transaction
  try {
    const newRequest = await createOutgoingRequest({
      action: "retire",
      address,
      year,
      quantity,
      behalf,
    });
    const transactionParameters = {
      to: process.env.REACT_APP_CONTRACT_ADDRESS, // Required except during contract publications.
      from: address, // must match user's active address.
      data: tokenContract.methods
        .burnAndRetire(BigInt(quantity * 10 ** 18), behalf, newRequest.data.id)
        .encodeABI(),
    };
    const transactionId = (await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    })) as string;
    setLoading(false);
    return {
      success: true,
      transactionId,
      status: (
        <>
          <div>
            Your token transfer has been initiated. If it is successful, we will
            retire the requested number of RECs on the MRETs registry. You can
            download the retirement certificiate on the History page.
          </div>
          <a
            className="transactionLink cursor-pointer font-bold text-blue-500 hover:text-blue-600"
            href={`https://mumbai.polygonscan.com/tx/${transactionId}`}
            target={"_blank"}
            rel="noreferrer"
          >
            Check out your transaction
          </a>
        </>
      ),
    };
  } catch (error) {
    //TODO delete the outgoing request here
    setLoading(false);
    console.log({ error });
    return { status: "There was an error making your request" };
  }
};

export const bridgeTokens = async (
  address: string,
  quantity: number,
  organizationId: string,
  setLoading: (loading: boolean) => void
) => {
  //input error handling
  if (!window.ethereum || address === null) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }

  if (!quantity || quantity <= 0) {
    return {
      status: "❌ Your quantity must be greater than 0",
    };
  }

  setLoading(true);
  try {
    const newRequest = await createOutgoingRequest({
      action: "bridge",
      address,
      quantity,
      organizationId,
    });
    const transactionParameters = {
      to: process.env.REACT_APP_CONTRACT_ADDRESS, // Required except during contract publications.
      from: address, // must match user's active address.
      data: tokenContract.methods
        .burnAndBridge(
          BigInt(quantity * 10 ** 18),
          organizationId,
          newRequest.data.id
        )
        .encodeABI(),
    };
    const transactionId = (await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    })) as string;
    setLoading(false);
    return {
      success: true,
      status: (
        <>
          <div>
            Your token transfer has been initiated. If it is successful, we will
            transfer the requested number of RECs to your provided account on
            the MRETs registry.
          </div>
          <br />
          <div>
            <b>Note:</b> You will need to accept the transfer in your MRETs
            account.
          </div>
          <a
            className="transactionLink cursor-pointer font-bold text-blue-500 hover:text-blue-600"
            href={`https://mumbai.polygonscan.com/tx/${transactionId}`}
            target={"_blank"}
            rel="noreferrer"
          >
            Check out your transaction
          </a>
        </>
      ),
    };
  } catch (error) {
    setLoading(false);
    console.log({ error });
    return { status: "There was an error making your request" };
  }
};

export const getTokenBalance = async (walletAddress: string) => {
  const balance = BigInt(
    await tokenContract.methods.balanceOf(walletAddress).call()
  );
  return Number(balance / BigInt(10 ** 18));
};
