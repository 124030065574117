import { Alert, Box, Flex, Loader, Table } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons";
import React from "react";
import { useGetPriceQuote } from "../data/priceQuote/useGetPriceQuote";

interface ICostTableProps {
  quantity: number;
  paymentMethod: "USD" | "GET";
}

const CostTable = ({ quantity, paymentMethod }: ICostTableProps) => {
  const {
    data: transactionCost,
    isLoading,
    isRefetching,
  } = useGetPriceQuote(quantity);
  console.log(transactionCost);
  const showLoader = isLoading || isRefetching || !transactionCost;
  const totalPrice =
    !showLoader && transactionCost ? (
      `$${transactionCost.toFixed(2)}`
    ) : (
      <Loader size={"sm"} />
    );
  const paymentProcessingFee =
    !showLoader && transactionCost ? (
      `$${Math.min(transactionCost * 0.008, 5).toFixed(2)}`
    ) : (
      <Loader size={"sm"} />
    );

  const getTotalCost = () => {
    if (paymentMethod === "GET") return `${(quantity * 1.01).toFixed(2)} GET`;
    return !showLoader && transactionCost ? (
      `$${(transactionCost + Math.min(transactionCost * 0.008, 5)).toFixed(2)}`
    ) : (
      <Loader size={"sm"} />
    );
  };

  const getServiceFee = () => `${(quantity / 100).toFixed(2)} GET`;

  return (
    <Box maw={"500px"}>
      {paymentMethod === "USD" && (
        <>
          <Flex my="md" fw="bold" gap={"3px"} align="center">
            Current Price:{" "}
            {!showLoader && transactionCost ? (
              `$${(transactionCost / quantity).toFixed(2)}/REC`
            ) : (
              <Loader size={"xs"} />
            )}
          </Flex>
          <Alert
            icon={<IconAlertTriangle />}
            title="Price May Vary"
            color="yellow"
            mb="md"
          >
            This price is not guaranteed. This is a live changing price based on
            demand in the market. Your final price will be displayed in your
            checkout form when you confirm.
          </Alert>
        </>
      )}
      <Table striped withBorder>
        <thead>
          <tr>
            <th>Item</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr key="transactionCost">
            <td>Retire {quantity} renewable energy credits</td>
            <td>{paymentMethod === "GET" ? `${quantity} GET` : totalPrice} </td>
          </tr>
          {paymentMethod === "USD" && (
            <tr key="usdProcessingFee">
              <td>Payment Processing Fee (.8% up to 5$)</td>
              <td>{paymentProcessingFee}</td>
            </tr>
          )}
          {paymentMethod === "GET" && (
            <tr key="getProcessingFee">
              <td>Service Fee (1%)</td>
              <td>{getServiceFee()}</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <th>Total Cost</th>
            <th>{getTotalCost()}</th>
          </tr>
        </tfoot>
      </Table>
    </Box>
  );
};

export default CostTable;
