import { createStyles, Text, UnstyledButton } from "@mantine/core";
import { IconCurrencyDollar, IconCurrencyGuarani } from "@tabler/icons";
import React from "react";

interface ISelectionButtonProps {
  selected?: boolean;
  context: "USD" | "GET";
  onClick: () => void;
}

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: "1px solid gray",
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
    },
  },
}));

const SelectionButton = ({
  selected,
  context,
  onClick,
}: ISelectionButtonProps) => {
  const { classes } = useStyles();
  const Icon = context === "USD" ? IconCurrencyDollar : IconCurrencyGuarani;

  return (
    <UnstyledButton
      onClick={onClick}
      bg={selected ? "blue" : undefined}
      className={classes.item}
    >
      <Icon size={32} color={selected ? "white" : undefined} />
      <Text
        size="xs"
        weight={500}
        mt={7}
        color={selected ? "white" : undefined}
      >
        Pay with {context}
      </Text>
    </UnstyledButton>
  );
};

export default SelectionButton;
