import { Paper, Text, Title } from "@mantine/core";
import React, { useContext } from "react";
import { ViewContext } from "../App";

const WalletCard = () => {
  const { wallet, balance } = useContext(ViewContext);

  if (!wallet || !balance) return <></>;
  return (
    <Paper p="md" withBorder shadow="md">
      <Title size="h4" mb="sm">
        GET Balance
      </Title>
      <Text>
        Wallet address: {wallet?.slice(0, 4)}...
        {wallet?.slice(wallet.length - 5)}
      </Text>
      <Text> Token balance: {balance} GET </Text>
    </Paper>
  );
};

export default WalletCard;
