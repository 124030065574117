import { Alert, Button, Loader } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons";
import { useMetaMask } from "metamask-react";
import React, { useContext, useEffect } from "react";
import { ViewContext } from "../../App";
import WalletCard from "../../components/WalletCard";
import { getTokenBalance } from "../../web3/util";

const MetamaskConnectView = () => {
  const { setBalance, setWallet, setIsCorrectChainId } =
    useContext(ViewContext);
  const { status, connect, account, chainId, switchChain } = useMetaMask();

  useEffect(() => {
    setWallet!(account ?? "");
    setIsCorrectChainId!(chainId === process.env.REACT_APP_CHAIN_ID!);
    if (account && chainId === process.env.REACT_APP_CHAIN_ID)
      getTokenBalance(account)
        .then((tokenBalance) => setBalance!(tokenBalance.toString() ?? ""))
        .catch(() => setBalance!(""));
    else {
      setBalance!("");
    }
  }, [account, chainId, setBalance, setWallet, setIsCorrectChainId]);

  if (chainId !== process.env.REACT_APP_CHAIN_ID)
    switchChain(process.env.REACT_APP_CHAIN_ID!);

  if (status === "connected" && chainId !== process.env.REACT_APP_CHAIN_ID)
    return (
      <Button
        color={"orange"}
        fullWidth
        size="lg"
        onClick={() => switchChain(process.env.REACT_APP_CHAIN_ID!)}
      >
        Switch to the polygon network
      </Button>
    );

  if (status === "unavailable")
    return (
      <Alert
        icon={<IconAlertTriangle />}
        title="Metamask Not Installed!"
        color="orange"
      >
        In order to make a transaction using GET, you must install Metamask and
        connect your wallet.
      </Alert>
    );

  return status !== "connected" ? (
    <Button size="lg" onClick={connect} fullWidth>
      {status === "connecting" ? <Loader color={"white"} /> : "Connect Wallet"}
    </Button>
  ) : (
    <WalletCard />
  );
};

export default MetamaskConnectView;
