import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import AuthContext from "./contexts/AuthContext";
import { RegisterAuthorizationHeaderInterceptor } from "./contexts/RegisterAuthorizationHeaderInterceptor";
import { MetaMaskProvider } from "metamask-react";
import MantineContext from "./contexts/MantineContext";
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-jgdz-bd5.us.auth0.com"
      clientId="eGLlv3YYvd80Ro1TyXRLOLp4XTTpDcYf"
      redirectUri={window.location.origin}
      audience="http://localhost:8000"
      scope="accept:requests"
      useRefreshTokens={true}
    >
      <AuthContext>
        <RegisterAuthorizationHeaderInterceptor>
          <MetaMaskProvider>
            <MantineContext>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </MantineContext>
          </MetaMaskProvider>
        </RegisterAuthorizationHeaderInterceptor>
      </AuthContext>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
