import { useQuery } from "react-query";
import applicationApi from "../applicationApi";

export function useGetPriceQuote(quantity: number) {
  return useQuery(
    "priceQuote",
    () =>
      applicationApi
        .get<number>(`/currentPrice?quantity=${quantity}`)
        .then((res) => res.data),
    { refetchInterval: 10000 }
  );
}
