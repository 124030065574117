import { useContext } from "react";
import {
  Anchor,
  createStyles,
  Navbar,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconLogout,
  IconHelp,
  IconCertificate,
  IconBuildingBridge,
  IconHistory,
  IconHomeEco,
  IconRecycle,
  IconCurrencyDollar,
} from "@tabler/icons";
import DarkThemeToggle from "../designSystem/Buttons/DarkThemeToggleButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Role, ViewContext } from "../../App";
import { ReactComponent as DarkThemeLogo } from "../../assets/darkThemeLogo1.svg";
import { ReactComponent as LightThemeLogo } from "../../assets/lightThemeLogo.svg";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: theme.spacing.sm,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

const data = [
  { label: "Retire", icon: IconCertificate },
  { label: "Buy", icon: IconCurrencyDollar },
  { label: "Bridge", icon: IconBuildingBridge },
  { label: "History", icon: IconHistory },
  { label: "Inventory", icon: IconHomeEco },
  { label: "Docs", icon: IconHelp },
  { label: "Incoming Bridge Requests", icon: IconRecycle },
];

const Nav = () => {
  const { classes, cx } = useStyles();
  const { logout } = useAuth0();
  const { role, setView, view } = useContext(ViewContext);
  const { colorScheme } = useMantineColorScheme();
  const isDarkTheme = colorScheme === "dark";

  const links = data
    .filter(
      (navItem) =>
        navItem.label !== "Incoming Bridge Requests" || role === Role.admin
    )
    .map((item) => (
      <a
        className={cx(classes.link, {
          [classes.linkActive]: item.label === view,
        })}
        href={item.label}
        key={item.label}
        onClick={(event) => {
          setView!(item.label);
          event.preventDefault();
        }}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} />
        <span>{item.label}</span>
      </a>
    ));

  return (
    <Navbar
      height={"calc(100vh - 90px)"}
      width={{ sm: 300 }}
      p="md"
      zIndex={199}
    >
      <Navbar.Section className={classes.header}>
        {isDarkTheme ? (
          <DarkThemeLogo width={120} height="100%" />
        ) : (
          <LightThemeLogo width={120} height="100%" />
        )}
        <DarkThemeToggle />
      </Navbar.Section>
      <Navbar.Section grow>{links}</Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Anchor<"button">
          className={classes.link}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </Anchor>
      </Navbar.Section>
    </Navbar>
  );
};

export default Nav;
