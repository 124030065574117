import { Table, Text, Title } from "@mantine/core";
import CenteredLoader from "../../components/designSystem/CenteredLoader";
import ContentPage from "../../components/designSystem/ContentPage";
import { useInventoryQuery } from "../../data/inventory/useInventoryQuery";

const Inventory = () => {
  const { data, isError, isLoading } = useInventoryQuery();

  if (isError) return <div>There was an error loading inventory</div>;
  if (isLoading) return <CenteredLoader />;
  return data ? (
    <ContentPage>
      <Title>Inventory</Title>
      <Text mb="xl">
        The table below displays a list of renewable energy certificates
        available to purchase or retire.
      </Text>
      <Table striped withBorder>
        <thead>
          <tr className="w-100">
            <th>Name</th>
            <th>Mrets ID</th>
            <th>Quantity</th>
            <th>Serial Number</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {data.map((cert) => (
            <tr key={cert.id}>
              <td>{cert.name}</td>
              <td>{cert.mrets_id}</td>
              <td>{cert.sum}</td>
              <td>{cert.serial_number_base}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ContentPage>
  ) : (
    <></>
  );
};

export default Inventory;
