import { Checkbox, Pagination, Table, Text, Title } from "@mantine/core";
import { DateTime } from "luxon";
import React, { useState } from "react";
import CenteredLoader from "../../components/designSystem/CenteredLoader";
import ContentPage from "../../components/designSystem/ContentPage";
import { useTransactionHistoryQuery } from "../../data/transactionHistory/useTransactionHistoryQuery";

const PendingTransactionsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hideIncomplete, setHideIncomplete] = useState(true);

  const { data, isError, isLoading } = useTransactionHistoryQuery();

  if (isError) return <div>Error loading transactions</div>;
  const filteredData = (data ?? []).filter(
    (tx) =>
      ["Complete", "TokenTransactionComplete"].includes(tx.status) ||
      !hideIncomplete
  );

  return data && !isLoading ? (
    <ContentPage>
      <Title>History</Title>
      <Text mb="xl">
        The table below shows a list of your previous transactions.
      </Text>
      <Checkbox
        id="hideIncomplete"
        checked={hideIncomplete}
        onChange={() => setHideIncomplete(!hideIncomplete)}
        label="Hide Incomplete Transactions"
      />
      <Table striped withBorder>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Status</th>
            <th>Quantity</th>
            <th>Polygon Transaction</th>
            <th>Certificate</th>
          </tr>
        </thead>

        <tbody>
          {filteredData
            .slice(
              currentPage * 8 - 8,
              Math.min(currentPage * 8, filteredData.length)
            )
            .map((tx) => (
              <tr key={tx.id}>
                <td>{DateTime.fromISO(tx.createdAt).toISODate()}</td>
                <td>{tx.bridgeRequest ? "Bridge" : "Retire"}</td>
                <td>{tx.status}</td>
                <td>{tx.quantity}</td>
                <td>
                  {tx.polygonTransactionId && (
                    <a
                      href={`https://mumbai.polygonscan.com/tx/${tx.polygonTransactionId}`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </td>
                <td>
                  {tx.mretsTxId && (
                    <a
                      href={`${
                        process.env.REACT_APP_SERVER_URL
                      }/certificate/${tx.mretsTxId!}`}
                    >
                      Download
                    </a>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {filteredData.length > 8 && (
        <Pagination
          total={Math.floor(filteredData.length / 8) + 1}
          page={currentPage}
          onChange={setCurrentPage}
        />
      )}
    </ContentPage>
  ) : (
    <CenteredLoader />
  );
};

export default PendingTransactionsList;
