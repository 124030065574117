import { Flex, Loader, MantineNumberSize } from "@mantine/core";

interface ICenteredLoaderProps {
  size?: MantineNumberSize;
}

const CenteredLoader = ({ size }: ICenteredLoaderProps) => (
  <Flex justify={"center"} align="center" w={"100%"} h="100%">
    <Loader size={size ?? "lg"} />
  </Flex>
);

export default CenteredLoader;
