import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

interface IAuthContextProps {
  children: React.ReactNode;
}

const AuthContext = ({ children }: IAuthContextProps) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
    return <></>;
  }
  return <>{children}</>;
};

export default AuthContext;
