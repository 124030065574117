import { useQuery } from "react-query";
import {
  BridgeRequest,
  CertificateDetail,
  GeneratorDetail,
  OrganizationDetail,
  TransactionDetail,
} from "../../types/appTypes";
import applicationApi from "../applicationApi";

type AdminDataResponse = {
  bridgeRequests: Array<BridgeRequest>;
  mretsData: {
    data: Array<TransactionDetail>;
    included: Array<OrganizationDetail | CertificateDetail | GeneratorDetail>;
  };
};

const fetcher = () =>
  applicationApi
    .get<AdminDataResponse>("/admin")
    .then((response) => response.data);

export function useBridgeRequestsQuery() {
  return useQuery("pendingBridgeRequests", fetcher);
}
