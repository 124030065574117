import { useQuery } from "react-query";
import applicationApi from "../applicationApi";

type transactionHistoryResponse = {
  id: string;
  userId: string;
  polygonAddress: string;
  quantity: number;
  polygonTransactionId: string | null;
  mretsTxId: string | null;
  status: string;
  createdAt: string;
  bridgeRequest?: { id: string };
  retirementRequest?: { id: string };
}[];

const fetcher = () =>
  applicationApi
    .get<transactionHistoryResponse>("/requests")
    .then((response) => response.data);

export function useTransactionHistoryQuery() {
  return useQuery("transactionHistory", fetcher);
}
