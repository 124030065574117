import { Title } from "@mantine/core";
import React from "react";
import CenteredLoader from "../../components/designSystem/CenteredLoader";
import ContentPage from "../../components/designSystem/ContentPage";
import NonIdealState from "../../components/designSystem/NonIdealState";
import PendingTransaction from "../../components/PendingTransaction";
import { useBridgeRequestsQuery } from "../../data/bridgeRequests/useBridgeRequestsQuery";

const AdminView = () => {
  const { isLoading, data } = useBridgeRequestsQuery();
  if (isLoading) return <CenteredLoader />;
  if (data) {
    const {
      bridgeRequests,
      mretsData: { included: relatedData, data: pendingTransactions },
    } = data;

    return (
      <ContentPage>
        <Title>Pending Bridge Requests</Title>
        {pendingTransactions.length === 0 && (
          <NonIdealState
            title="No pending requests"
            description="There are no pending requests for you to accept"
          />
        )}
        {pendingTransactions.map((tx) => (
          <PendingTransaction
            key={tx.id}
            tx={tx}
            relatedData={relatedData}
            bridgeRequests={bridgeRequests}
          />
        ))}
      </ContentPage>
    );
  }
  return <div>There was an error loading pending bridge requests.</div>;
};

export default AdminView;
