import React, { useState } from "react";
import {
  BridgeRequest,
  CertificateDetail,
  GeneratorDetail,
  OrganizationDetail,
  TransactionDetail,
} from "../types/appTypes";
import applicationApi from "../data/applicationApi";
import { Anchor, Button, Modal, Paper, Stack, Text } from "@mantine/core";
import { useQueryClient } from "react-query";

interface IPendingTransactionProps {
  tx: TransactionDetail;
  relatedData: Array<OrganizationDetail | CertificateDetail | GeneratorDetail>;
  bridgeRequests: Array<BridgeRequest>;
}

const PendingTransaction = ({
  tx,
  relatedData: relatedEntities,
  bridgeRequests,
}: IPendingTransactionProps) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const fromOrganizationId = tx.relationships.from_organization.data.id;
  const certificateId = tx.relationships.certificate.data.id;
  const organization = relatedEntities.find(
    (entity) =>
      entity.type === "organizations" && entity.id === fromOrganizationId
  ) as OrganizationDetail | undefined;
  const certificate = relatedEntities.find(
    (entity) => entity.type === "certificates" && entity.id === certificateId
  ) as CertificateDetail | undefined;
  const generator = relatedEntities.find(
    (entity) =>
      entity.type === "generators" &&
      entity.id === certificate?.relationships.generator.data.id
  ) as GeneratorDetail | undefined;

  const serialNumber = certificate?.attributes.serial_number_base;

  const relatedBridgeRequest = bridgeRequests.find(
    (req) =>
      req.serialNumber === serialNumber &&
      req.serialNumberStart === tx.attributes.serial_number_start &&
      req.serialNumberEnd === tx.attributes.serial_number_end
  );

  const mretsId = generator?.attributes.mrets_id;

  const handleAcceptAndMintClick = async () => {
    const acceptAndMintResponse = await applicationApi.post<{ receipt: any }>(
      "/accept-and-mint",
      {
        mretsTransaction: tx,
        bridgeRequest: relatedBridgeRequest,
      }
    );
    setIsAcceptModalOpen(false);
    alert(
      `successfully accepted transfer - Transaction Hash: ${acceptAndMintResponse.data.receipt?.transactionHash}`
    );
    queryClient.invalidateQueries("pendingBridgeRequests");
  };

  return (
    <>
      {mretsId && (
        <Modal
          opened={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          title="Accept and Mint"
        >
          <Stack>
            <Text>
              Before minting, please confirm that the MRETs ID: {mretsId} is
              Green-E certified by comparing to the list{" "}
              <Anchor
                href="https://www.green-e.org/sfdc/reports-data.php"
                target="_blank"
                rel="noreferrer"
              >
                here
              </Anchor>
              .
            </Text>
            <Button onClick={handleAcceptAndMintClick}>Confirm</Button>
          </Stack>
        </Modal>
      )}
      <Paper key={tx.id} shadow={"md"} withBorder p="lg">
        <Stack sx={{ gap: ".5rem" }}>
          <div>Quantity: {tx.attributes.quantity} </div>
          <div>
            Organization:{" "}
            {organization?.attributes.name ?? "No Organization Found"}
          </div>
          <div>
            Mrets ID: {generator?.attributes.mrets_id ?? "No Mrets ID Found"}
          </div>
          <div>Transaction ID: {tx.id}</div>
          <div>
            Polygon Address:{" "}
            {relatedBridgeRequest?.polygonAddress ?? "No bridge request found"}
          </div>
          <Button
            disabled={!generator?.attributes.mrets_id || !relatedBridgeRequest}
            onClick={() => setIsAcceptModalOpen(true)}
            w="200px"
          >
            Accept and Mint
          </Button>
        </Stack>
      </Paper>
    </>
  );
};

export default PendingTransaction;
