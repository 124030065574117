import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

import { useAuth0 } from "@auth0/auth0-react";
import applicationApi from "../data/applicationApi";

/**
 * RegisterAuthorizationHeaderInterceptor will attempt to add an interceptor to every
 * outgoing request that appends the auth header. The requests must be made via the
 * api that wraps an axios instance. Any normal fetch or axios calls will not reach
 * this code.
 */
export const RegisterAuthorizationHeaderInterceptor: FunctionComponent<
  PropsWithChildren<{}>
> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [interceptorIsRegistered, setInterceptorIsRegistered] = useState(false);

  useEffect(() => {
    const ejectionMarker = applicationApi.interceptors.request.use(
      async (config) => {
        try {
          const token = await getAccessTokenSilently();
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          };
        } catch (e) {
          console.error(e);
        }
        return config;
      }
    );
    setInterceptorIsRegistered(true);
    return () => {
      applicationApi.interceptors.request.eject(ejectionMarker);
    };
  }, [getAccessTokenSilently]);

  if (interceptorIsRegistered) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};
