import { createStyles, Container, Anchor, Text } from "@mantine/core";
import React from "react";
const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: "1rem",
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },
}));

const Footer = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Text color="dimmed" size="sm">
          © 2023 RecTrade
        </Text>
        <Anchor<"a">
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_TRACKER_SITE}/address/${process.env.REACT_APP_CONTRACT_ADDRESS}`}
        >
          Smart Contract: {process.env.REACT_APP_CONTRACT_ADDRESS}
        </Anchor>
      </Container>
    </div>
  );
};

export default Footer;
