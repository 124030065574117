import { Container } from "@mantine/core";
import React, { useContext } from "react";
import { ViewContext } from "../App";
import NonIdealState from "../components/designSystem/NonIdealState";
import AdminView from "./Admin/AdminView";
import IncomingBridgeView from "./User/IncomingBridgeView";
import Inventory from "./User/Inventory";
import OutgoingBridgeView from "./User/OutgoingBridgeView";
import PendingTransactionsList from "./User/PendingTransactionList";
import RetireView from "./User/RetireView";

const AppContentWrapper = () => {
  const { view } = useContext(ViewContext);
  switch (view) {
    case "Retire":
      return <RetireView />;
    case "History":
      return <PendingTransactionsList />;
    case "Inventory":
      return <Inventory />;
    case "Bridge":
      return <IncomingBridgeView />;
    case "Incoming Bridge Requests":
      return <AdminView />;
    case "Docs":
      return <NonIdealState title="Coming Soon" />;
    case "Buy":
      return <OutgoingBridgeView />;
    default:
      return (
        <Container mt="md">
          Select an option from the navigation to get started.
        </Container>
      );
  }
};

export default AppContentWrapper;
