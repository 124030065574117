import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stepper,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { ReactNode, useContext, useState } from "react";
import { ViewContext } from "../../App";
import CostTable from "../../components/CostTable";
import ContentPage from "../../components/designSystem/ContentPage";
import SelectionCard from "../../components/designSystem/SelectionButton";
import { makeCheckoutFormRequest } from "../../data/data";
import { RetireFormData } from "../../types/appTypes";
import { retireTokens } from "../../web3/util";
import MetamaskConnectView from "./MetamaskConnectView";

const RetireView = () => {
  const { wallet, isCorrectChainId } = useContext(ViewContext);
  const [status, setStatus] = useState<ReactNode>("");
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 1 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const form = useForm<RetireFormData>({
    initialValues: {
      quantity: "",
      year: "2022",
      behalf: "",
      paymentMethod: "USD",
    },
  });

  const handleSubmit = async () => {
    const burnTokenResponse = await retireTokens(
      wallet!,
      parseInt(form.values.quantity ?? 0),
      parseInt(form.values.year),
      form.values.behalf,
      setLoading
    );
    if (burnTokenResponse.success) {
      setActive(2);
    }
    setStatus(burnTokenResponse.status ?? "");
  };

  const submitButtonDisabled =
    !form.values.quantity ||
    (form.values.paymentMethod === "GET" && (!wallet || !isCorrectChainId));

  const confirmOrder = () => {
    if (form.values.paymentMethod === "GET") handleSubmit();
    else makeCheckoutFormRequest(form.values, "retirement");
  };
  return (
    <ContentPage>
      <Title>Retire</Title>
      <Text>
        Use this form to submit a request to retire renewable energy credits.
      </Text>
      <Stepper maw={"500px"} active={active} breakpoint="sm">
        <Stepper.Step label="Enter Details">
          <Box sx={{ maxWidth: 350 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setStatus("");
                nextStep();
              }}
            >
              <TextInput
                label="Quantity to Retire"
                type="number"
                name="quantity"
                id="quantity"
                min={1}
                step={1}
                required
                withAsterisk
                {...form.getInputProps("quantity")}
              />
              <TextInput
                label="Retirement Year"
                type="number"
                name="year"
                id="year"
                min={2021}
                step={1}
                max={2023}
                required
                withAsterisk
                {...form.getInputProps("year")}
              />
              <TextInput
                label="Retire on Behalf of"
                type="text"
                name="behalf"
                id="behalf"
                {...form.getInputProps("behalf")}
              />
              <SimpleGrid cols={2} my="md">
                <SelectionCard
                  context="USD"
                  onClick={() => form.setValues({ paymentMethod: "USD" })}
                  selected={form.values.paymentMethod === "USD"}
                />
                <SelectionCard
                  context="GET"
                  onClick={() => form.setValues({ paymentMethod: "GET" })}
                  selected={form.values.paymentMethod === "GET"}
                />
              </SimpleGrid>
              {form.values.paymentMethod === "GET" && <MetamaskConnectView />}

              <Button
                loading={loading}
                disabled={submitButtonDisabled}
                mt="md"
                type="submit"
              >
                Generate Quote
              </Button>
            </form>
          </Box>
        </Stepper.Step>
        <Stepper.Step label="Cost and Confirmation">
          <CostTable
            paymentMethod={form.values.paymentMethod}
            quantity={parseInt(form.values.quantity) ?? 0}
          />
          <Flex mt="md" gap={"sm"}>
            <Button color="gray" onClick={prevStep} variant="outline">
              Back
            </Button>
            <Button
              loading={loading}
              disabled={submitButtonDisabled}
              onClick={confirmOrder}
            >
              {loading ? "Confirming on Metamask Extension" : "Confirm"}
            </Button>
          </Flex>
        </Stepper.Step>
        <Stepper.Completed>
          <Button
            variant="outline"
            onClick={() => {
              setActive(0);
              form.reset();
              setStatus("");
            }}
          >
            Retire More Credits
          </Button>{" "}
        </Stepper.Completed>
      </Stepper>

      {status && <Text>{status}</Text>}
    </ContentPage>
  );
};

export default RetireView;
